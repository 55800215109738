import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {Group} from "./group.interface";


@Injectable({providedIn: 'root'})
export class GroupService {
    constructor(private http: ApiHttpService) {

    }

    list(qp?: any): Observable<HttpResponse<Group[]>> {
        return this.http.get(this.e, qp);
    }

    save(data: Group): Observable<HttpResponse<Group>> {
        return this.http.save(this.e + '/{id}', data);
    }

    remove(id: number | string): Observable<HttpResponse<void>> {
        return this.http.remove(this.e + `/${id}`);
    }

    get(id: number | string, qp?: any): Observable<HttpResponse<Group>> {
        return this.http.get(this.e + `/${id}`, qp);
    }

    readonly e = '/groups';
}
