import {EventEmitter, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {FileUploaderModalComponent} from "./file-uploader-modal.component";


interface UploadRequest {
    target: string,
    objectId: string | number,
    modalTitle: string,
    accept: string
}

@Injectable({
    providedIn: 'root'
})
export class FileUploaderService {
    bsModalRef: BsModalRef;
    response: EventEmitter<any>;

    constructor(private modalService: BsModalService) {
        this.response = new EventEmitter();
    }

    showModal(data: UploadRequest) {
        this.bsModalRef = this.modalService.show(FileUploaderModalComponent, {
            initialState:        _.merge(data, {response: this.response}),
            class:               'modal-dialog-centered',
            keyboard:            false,
            ignoreBackdropClick: true
        });
    }
}
