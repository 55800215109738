import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {AuthComponent} from "./auth.component";
import {AuthGuard} from "./auth.guard";


const authRoutes: Routes = [
    {
        path:      'auth',
        component: AuthComponent,
        children:  [
            {
                path:       '',
                pathMatch:  'full',
                redirectTo: 'login'
            },
            {
                path:         'login',
                loadChildren: './login/login.module#LoginModule',
                canActivate:  [AuthGuard]
            },
            {
                path:         'forgot-password',
                loadChildren: './forgot-password/forgot-password.module#ForgotPasswordModule',
                canActivate:  [AuthGuard]
            },
            {
                path:         '404',
                loadChildren: './page404/page404.module#Page404Module'
            }
        ],
    }
];


@NgModule({
    imports: [RouterModule.forRoot(authRoutes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule]
})
export class AuthRoutingModule {
}
