import {HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {ApiHttpService} from "../api-http.service";
import {User} from "../user/user.interface";
import {LoginRequest} from "./auth.interface";


@Injectable({providedIn: 'root'})
export class AuthService {

    constructor(private http: ApiHttpService) {
    }

    login(data: LoginRequest): Observable<HttpResponse<User>> {
        return this.http.post<User>('/auth/login', data);
    }

    masqueradeLogin(id: number | string): Observable<HttpResponse<User>> {
        return this.http.post('/auth/masquerade_login', {user: id});
    }
}
