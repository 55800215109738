import {Injectable, ViewContainerRef} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter, tap} from "rxjs/operators";


interface Breadcrumb {
    title: string,
    route: string,
    queryParams?: any;
}


@Injectable()
export class ContentHeaderService {
    private breadcrumbHome: Breadcrumb;
    private _pageTitle: string = '';
    private _actionContainer: ViewContainerRef;
    private _isPageTitleHidden = false;
    private _clearPageAction = true;

    constructor(private router: Router) {
        this.clearActionContainer();
    }

    private _breadcrumb: Array<Breadcrumb> = [];

    get pageTitle() {
        return this._pageTitle;
    }

    get breadcrumb() {
        let items = [];
        if (this.breadcrumbHome)
            items.push(this.breadcrumbHome);
        return items.concat(this._breadcrumb);
    }

    get isPageTitleHidden(): boolean {
        return this._isPageTitleHidden
    }

    setPageTitle(title: string) {
        setTimeout(() => this._pageTitle = title);
    }

    setBreadcrumb(items: Array<Breadcrumb>) {
        setTimeout(() => this._breadcrumb = items);
    }

    setBreadcrumbHome(item: Breadcrumb) {
        this.breadcrumbHome = item;
    }

    clearPageActionOnRouteChange(v: boolean) {
        this._clearPageAction = v;
    }

    set pageActionContainer(container: ViewContainerRef) {
        this._actionContainer = container;
    }

    set pageAction(tpl) {
        setTimeout(() => {
            this._actionContainer.clear();
            this._actionContainer.createEmbeddedView(tpl);
        });
    }

    set isPageTitleHidden(value: boolean) {
        setTimeout(() => this._isPageTitleHidden = value);
    }

    private clearActionContainer() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd && this._clearPageAction))
            .pipe(tap(() => {
                try {
                    this._actionContainer.clear();
                } catch (e) {

                }
            }))
            .subscribe()
    }


}
