import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from "../shared/shared.module";
import {AuthRoutingModule} from "./auth-routing.module";
import {AuthComponent} from './auth.component';
import {AuthGuard} from "./auth.guard";


@NgModule({
    imports:      [
        CommonModule,
        SharedModule,

        AuthRoutingModule
    ],
    declarations: [AuthComponent],
    providers:    [AuthGuard]
})
export class AuthModule {
}
