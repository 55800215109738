import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {Observable} from 'rxjs';
import {UserService} from "../core/user.service";


@Injectable({
    providedIn: 'root'
})
export class UserGuard implements CanActivate {

    constructor(private user: UserService,
                private router: Router,
                private t: ToastrService) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.user.isAnonymous || this.user.isAdminOrStaff) {
            this.t.error('Page cannot be accessed. Please login with user credential');
            this.router.navigate(['/auth/login'], {queryParams: {next: encodeURIComponent(state.url)}});
            return false;
        }

        return true;
    }
}
