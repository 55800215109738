import {Injectable} from '@angular/core';
import {ApiHttpService} from "./api-http.service";
import {AuthService} from "./auth/auth.service";
import {CloudFilesService} from "./cloudfiles/cloud-files.service";
import {FTPProcessLogService} from "./ftp-process-log/ftp-process-log.service";
import {GroupService} from "./group/group.service";
import {NotificationService} from "./notification/notification.service";
import {OrganizationService} from "./organization/organization.service";
import {PermissionService} from "./permission/permission.service";
import {ProbeService} from "./probe/probe.service";
import {ReportService} from "./report/report.service";
import {SensorsLogCommentService} from "./sensors-log-comment/sensors-log-comment.service";
import {SystemService} from "./system/system.service";
import {UserService} from "./user/user.service";


@Injectable({providedIn: 'root'})
export class ApiSdkService {

    constructor(public http: ApiHttpService,
                public auth: AuthService,
                public system: SystemService,
                public organization: OrganizationService,
                public user: UserService,
                public group: GroupService,
                public permission: PermissionService,
                public probe: ProbeService,
                public sensorsLogComment: SensorsLogCommentService,
                public notification: NotificationService,
                public report: ReportService,
                public ftpProcessLog: FTPProcessLogService,
                public cloudfile: CloudFilesService) {
    }

}
