import {Component, OnInit} from '@angular/core';
import {AppStartupService} from "../app-startup.service";


@Component({
    selector:    'app-auth',
    templateUrl: './auth.component.html',
    styleUrls:   ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

    constructor(public startupSrv: AppStartupService) {
    }

    ngOnInit() {
    }

}
