import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {ExComponent} from "../core/classes/ex-component";
import {ContentHeaderService} from "../core/content-header.service";
import {UserService} from "../core/user.service";
import {LeftMenuService} from "../shared/components/left-menu/left-menu.service";


@Component({
    selector:    'app-sys-admin',
    templateUrl: './admin.component.html',
    styleUrls:   ['./admin.component.scss']
})
export class AdminComponent extends ExComponent implements OnInit {
    @ViewChild('pageActionContainer', {read: ViewContainerRef}) _vcr;

    constructor(private leftMenu: LeftMenuService,
        public contentHeader: ContentHeaderService,
        public u: UserService,
        private router: Router) {
        super();
    }

    ngOnInit() {
        this.contentHeader.setBreadcrumbHome({ title: 'Home', route: '/a' });

        this.leftMenu.items.next([
            {
                title: 'Super Admin',
                isVisible: this.u.isSystemStaffOrAdmin,
                items: [
                    {
                        title:     'Organizations',
                        icon:      'mdi mdi-domain',
                        route:     '/a/organizations',
                        isVisible: this.u.hasPerm('organization.*')
                    },
                    {
                        title:     'FTP Process Logs',
                        icon:      'mdi mdi-server-network',
                        route:     '/a/ftp-process-log',
                        isVisible: this.u.hasPerm('organization.*')
                    }
                ],
            },
            {
                title: 'Admin',
                isVisible: this.u.isAdminOrStaff,
                items: [
                    {
                        title: 'Probe Dashboard',
                        icon: 'mdi mdi-speedometer',
                        route: '/a/probes',
                        isVisible: this.u.hasPerm('probe.*')
                    },
                    {
                        title: 'Users',
                        icon: 'mdi mdi-account',
                        route: '/a/users',
                        isVisible: this.u.hasPerm('user.*')
                    },
                    {
                        title:     'Farms',
                        icon:      'mdi mdi-account-group',
                        route:     '/a/groups',
                        isVisible: this.u.hasPerm('group.*')
                    },
                    {
                        title:     'Notifications',
                        icon:      'mdi mdi-bell',
                        route:     '/a/notifications',
                        isVisible: this.u.hasPerm('notification.*') && !this.u.isSystemStaffOrAdmin
                    },
                    {
                        title:     'Reports',
                        icon:      'mdi mdi-file-chart',
                        route:     '/a/report',
                        isVisible: this.u.hasPerm('report.view_efficiencyreport')
                    }

                ],
            },
        ]);

        this.contentHeader.pageActionContainer = this._vcr;

        this.router.events
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                e => {
                    if (e instanceof NavigationStart) {
                        this.ui.isRouteLoading = true;
                    } else if (e instanceof NavigationCancel ||
                        e instanceof NavigationError ||
                        e instanceof NavigationEnd) {

                        this.ui.isRouteLoading = false
                    }
                }
            )

    }

}
