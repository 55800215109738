import {Injectable, Injector} from '@angular/core';
import {ApiSdkService} from "./core/api-sdk/api-sdk.service";
import {Organization} from "./core/api-sdk/organization/organization.interface";


@Injectable()
export class AppStartupService {
    private _data: Organization;

    constructor(private injector: Injector) {
    }

    get data(): Organization {
        return this._data;
    }

    load(): Promise<any> {
        this._data = null;

        return this.api.organization.list({by_domain: true})
            .toPromise()
            .then(resp => this._data = resp.body.length == 0 ? null : resp.body[0])
            .catch(() => Promise.resolve())
    }

    private get api(): ApiSdkService {
        return this.injector.get(ApiSdkService);
    }
}
