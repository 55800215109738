import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserComponent} from "./user.component";
import {UserGuard} from "./user.guard";


const routes: Routes = [
    {
        path:        'u',
        component:   UserComponent,
        canActivate: [UserGuard],
        children:    [
            {
                path:       '',
                pathMatch:  'full',
                redirectTo: 'probes'
            },
            {
                path:         'probes',
                // Instead of pointing directly to ./probe/probe.module, string must be different
                // since there is a open bug https://github.com/angular/angular-cli/issues/10128
                loadChildren: '../user/probe/probe.module#ProbeModule'
            },
            {
                path:         'notifications',
                loadChildren: '../user/notification/notification.module#NotificationModule'
            },
            {
                path:         'profile',
                loadChildren: './profile/profile.module#ProfileModule'
            },
            {
                path:         'report',
                loadChildren: '../admin/report/report.module#ReportModule'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule {
}
