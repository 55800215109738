import {HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {Subscription} from "./subscription.interface";


export class SubscriptionClass {
    readonly e = '/notifications/subscriptions';

    constructor(private http: ApiHttpService) {
    }

    save(data: Subscription): Observable<HttpResponse<Subscription>> {
        return this.http.save(this.e, data);
    }

    delete(id: string | number): Observable<HttpResponse<Subscription>> {
        return this.http.remove(`${this.e}/${id}`);
    }

    list(qs?: any): Observable<HttpResponse<Subscription[]>> {
        return this.http.get(this.e, qs);
    }
}


export const SUBSCRIPTION_KIND = {
    EFFICIENCY_REPORT: 'efficiency_report',
    SOIL_REPORT:       'soil_report'
}
