import {HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {Permission} from "./permission.interface";


@Injectable({providedIn: 'root'})
export class PermissionService {
    constructor(private http: ApiHttpService) {
    }

    list(): Observable<HttpResponse<Permission[]>> {
        return this.http.get(this.e);
    }

    readonly e = '/permissions';
}
