import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "../core/user.service";


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private user: UserService, private router: Router) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (this.user.isAnonymous)
            return true;

        let nextParam = next.queryParamMap.get('next') || '';
        if (nextParam) nextParam = '/' + decodeURIComponent(nextParam)

        let routePrefix = '/u';
        if (this.user.isAdminOrStaff) routePrefix = '/a'

        return this.router.navigateByUrl(`${routePrefix}${nextParam}`).then(() => false);
    }
}
