import {EventEmitter, Injectable} from '@angular/core';
import {ApiSdkService} from "../../../core/api-sdk/api-sdk.service";
import {Notification} from "../../../core/api-sdk/notification/notification.interface";
import {LoggerService} from "../../../core/logger.service";


@Injectable()
export class HeaderService {
    notifications: Notification[];
    notificationCount: number = 0;

    constructor(private api: ApiSdkService,
                private l: LoggerService) {
    }

    clearNotification() {
        this.notificationCount = 0;
        this.notifications = [];
    }

    loadNotification(): EventEmitter<void> {
        const event = new EventEmitter<void>();

        this.api.notification.list({unread: true, kind: 'probe-status-web', page_size: 3})
            .subscribe(
                resp => {
                    this.notifications = resp.body;
                    event.emit();
                    event.complete();
                    this.notificationCount = resp['meta'].count
                },
                e => {
                    event.emit();
                    event.complete();
                    this.l.error('notification error loading', e)
                }
            );

        return event;
    }
}
