import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {SubscriptionClass} from "./subscription.class";


@Injectable({providedIn: 'root'})
export class NotificationService {
    constructor(private http: ApiHttpService) {
    }

    list(qp?: any): Observable<HttpResponse<Notification[]>> {
        return this.http.get(this.e, qp);
    }

    get subscription() {
        return new SubscriptionClass(this.http);
    }

    readonly e = '/notifications';
}
