import {Injectable} from "@angular/core";
import {ApiHttpService} from "../api-http.service";


@Injectable({providedIn: 'root'})
export class FTPProcessLogService {
    constructor(private http: ApiHttpService) {
    }

    list(qp?: any) {
        return this.http.get(this.e, qp)
    }

    readonly e = '/ftp-process-logs';
}
