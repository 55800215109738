import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from "../shared/shared.module";

import {AdminRoutingModule} from './admin-routing.module';
import {AdminComponent} from './admin.component';


@NgModule({
    declarations: [AdminComponent],
    imports:      [
        CommonModule,
        SharedModule.forSubRoot(),

        AdminRoutingModule
    ],
})
export class AdminModule {
}
