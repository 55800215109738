import {Injectable} from '@angular/core';
import MetisMenu from "metismenujs";
import {ReplaySubject} from "rxjs/internal/ReplaySubject";
import {mergeMap} from "rxjs/operators";
import Utils from "../../../core/utils";


@Injectable({
    providedIn: 'root'
})
export class LeftMenuService {
    metismenu: ReplaySubject<MetisMenu>;
    items: ReplaySubject<Array<any>>;

    constructor() {
        this.metismenu = new ReplaySubject<MetisMenu>(1);
        this.items = new ReplaySubject<Array<any>>(1);

        this.items
            .pipe(mergeMap(() => this.metismenu))
            .subscribe(mm => {
                setTimeout(() => mm.update(), 1);
            });
    }

    get isVisible() {
        return !(this.bodyElement.className.indexOf('enlarged') > -1);
    }

    get isSmallWindow() {
        return Utils.isSmallWindow();
    }

    toggle() {
        this.isVisible ? this.hide() : this.show();
    }

    show() {
        this.bodyElement.className = this.bodyElement.className.replace('enlarged', '');
    }

    hide() {
        if (this.isVisible)
            this.bodyElement.className = ' enlarged';
    }

    private get bodyElement() {
        return document.getElementById('body');
    }
}
