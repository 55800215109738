import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from "./admin.component";
import {AdminGuard} from "./admin.guard";


const routes: Routes = [
    {
        path:        'a',
        component:   AdminComponent,
        canActivate: [AdminGuard],
        children:    [
            {
                path:       '',
                pathMatch:  'full',
                redirectTo: 'probes'
            },
            {
                path:         'organizations',
                loadChildren: './organization/organization.module#OrganizationModule'
            },
            {
                path:         'users',
                loadChildren: './user/user.module#UserModule'
            },
            {
                path:         'groups',
                loadChildren: './group/group.module#GroupModule'
            },
            {
                path:         'probes',
                loadChildren: './probe/probe.module#ProbeModule'
            },
            {
                path:         'notifications',
                loadChildren: './notification/notification.module#NotificationModule'
            },
            {
                path:         'report',
                loadChildren: './report/report.module#ReportModule'
            },
            {
                path:         'ftp-process-log',
                loadChildren: './ftp-process-log/ftp-process-log.module#FtpProcessLogModule'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AdminRoutingModule {
}
