import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AppStartupService} from "../../../app-startup.service";
import {ApiSdkService} from "../../../core/api-sdk/api-sdk.service";
import {LoggerService} from "../../../core/logger.service";
import {UserService} from "../../../core/user.service";
import {LeftMenuService} from "../left-menu/left-menu.service";
import {HeaderService} from "./header.service";


@Component({
    selector:      'app-header',
    templateUrl:   './header.component.html',
    styleUrls:     ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
    ui: any;

    constructor(public leftMenu: LeftMenuService,
                public user: UserService,
                private router: Router,
                public route: ActivatedRoute,
                public startupSrv: AppStartupService,
                private api: ApiSdkService,
                private l: LoggerService,
                public header: HeaderService) {
    }

    ngOnInit() {
        this.ui = {isNotificationLoading: true};
        this.header.loadNotification().subscribe(() => this.ui.isNotificationLoading = false)
    }

    logout() {
        this.user.clear();
        this.router.navigate(['/auth/login']);
    }

    exitMasquerade() {
        this.user.exitMasquerade();
        // route.navigate doesn't reload if it's same url
        window.location.href = '/';
    }

    clearNotification() {
        this.header.clearNotification();
        this.api.notification.list({page_size: 1, mark_read: true}).subscribe()
    }
}
