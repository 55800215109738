import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {ExComponent} from "../core/classes/ex-component";
import {ContentHeaderService} from "../core/content-header.service";
import {UserService} from "../core/user.service";
import {LeftMenuService} from "../shared/components/left-menu/left-menu.service";


@Component({
    selector:    'app-user',
    templateUrl: '../admin/admin.component.html',
    styleUrls:   ['../admin/admin.component.scss']
})
export class UserComponent extends ExComponent implements OnInit {
    @ViewChild('pageActionContainer', {read: ViewContainerRef}) _vcr;

    constructor(private leftMenu: LeftMenuService,
                public contentHeader: ContentHeaderService,
                public u: UserService,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.contentHeader.setBreadcrumbHome({title: 'Home', route: '/u'});
        this.contentHeader.pageActionContainer = this._vcr;

        this.initLeftMenu();

        this.router.events
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                e => {
                    if (e instanceof NavigationStart) {
                        this.ui.isRouteLoading = true;
                    } else if (e instanceof NavigationCancel ||
                        e instanceof NavigationError ||
                        e instanceof NavigationEnd) {

                        this.ui.isRouteLoading = false
                    }
                }
            )
    }

    private initLeftMenu() {
        const children: any = [
            {
                title:               'All',
                route:               '/u/probes',
                queryParamsHandling: 'merge',
                queryParams:         {group: undefined}
            }
        ];

        this.u.detail.value.groups.forEach((group: any) => {
            children.push({
                title:               group.name,
                route:               './probes',
                queryParamsHandling: 'merge',
                queryParams:         {group: group.id}
            })
        });

        this.leftMenu.items.next([
            {
                title: 'Home',
                items: [
                    {
                        title:    'Probe Dashboard',
                        icon:     'mdi mdi-speedometer',
                        children: children
                    },
                    {
                        title: 'Notifications',
                        icon:  'mdi mdi-bell',
                        route: '/u/notifications',
                    },
                    {
                        title: 'Reports',
                        icon:  'mdi mdi-file-chart',
                        route: '/u/report',
                        isVisible: this.u.hasPerm('report.view_efficiencyreport')
                    },
                ],
            },
        ]);
    }

}
