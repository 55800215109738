import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import MetisMenu from 'metismenujs';
import Utils from "../../../core/utils";
import { LeftMenuService } from "./left-menu.service";
import { Router, Event, NavigationEnd, NavigationError } from '@angular/router';


@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LeftMenuComponent implements OnInit {
    @ViewChild('metismenu') metismenu: ElementRef;

    constructor(public sidebarSrv: LeftMenuService, private router: Router) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.initLeftSideMenuVisibility();
            }
            if (event instanceof NavigationError) {
                this.initLeftSideMenuVisibility();
            }
        })
    }

    ngOnInit() {
        this.initMetismenu();
        this.initLeftSideMenuVisibility();
    }

    private initMetismenu() {
        const mm = new MetisMenu(this.metismenu.nativeElement);
        this.sidebarSrv.metismenu.next(mm);
    }

    @HostListener('window:resize')
    private onResize($event) {
        this.initLeftSideMenuVisibility();
    }

    private initLeftSideMenuVisibility() {
        if (Utils.isSmallWindow()) {
            setTimeout(() => this.sidebarSrv.hide(), 1);
        } else {
            this.sidebarSrv.show();
        }
    }
}
