import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {ApiConfig} from "./api-sdk/api-sdk.interface";
import {ContentHeaderService} from "./content-header.service";


@NgModule({
    imports: [CommonModule],
})
export class CoreModule {
    static forRoot(apiUri: string, requestTimeoutSec: number): ModuleWithProviders {
        return {
            ngModule:  CoreModule,
            providers: [
                ContentHeaderService,
                {
                    provide:  'config',
                    useValue: <ApiConfig>{apiUrl: apiUri, requestTimeout: requestTimeoutSec}
                }
            ],

        }
    }
}
