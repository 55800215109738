import {Injectable} from "@angular/core";
import {ApiHttpService} from "../api-http.service";


@Injectable({providedIn: 'root'})
export class CloudFilesService {
    constructor(private http: ApiHttpService) {
    }

    remove(id: string | number) {
        return this.http.remove(`${this.e}/${id}`)
    }

    readonly e = '/cloudfiles';
}
