import {HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs/internal/Observable";
import {ApiHttpService} from "../api-http.service";
import {ChangePasswordRequest, User} from "./user.interface";


@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: ApiHttpService) {
    }

    save(data: User): Observable<HttpResponse<User>> {
        return this.http.save(this.e + '/{id}', data);
    }

    list(qp?: any): Observable<HttpResponse<Array<User>>> {
        return this.http.get(this.e, qp);
    }

    changePassword(userId: number | string, data: ChangePasswordRequest): Observable<HttpResponse<void>> {
        return this.http.put(`/users/${userId}/actions/change_password`, data);
    }

    resetPasswordRequest(email: string): Observable<HttpResponse<void>> {
        return this.http.post(`${this.e}/actions/password_reset`, {email: email});
    }

    resetPassword(data?: any): Observable<HttpResponse<void>> {
        return this.http.put(`${this.e}/actions/password_reset`, data);
    }

    get(id: number | string): Observable<HttpResponse<User>> {
        return this.http.get(`${this.e}/${id}`);
    }

    remove(id: number | string): Observable<HttpResponse<void>> {
        return this.http.remove(`${this.e}/${id}`);
    }

    readonly e = '/users';
}
