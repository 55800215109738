import {Injectable} from "@angular/core";
import {ApiHttpService} from "../api-http.service";


@Injectable({providedIn: 'root'})
export class ReportService {
    readonly e = '/reports'

    constructor(private http: ApiHttpService) {
    }

    downloadEfficiencyReport(startDate: Date, endDate: Date, probeIds: number[]) {
        const queryParams = {
            start_date: startDate.toISOString(),
            end_date:   endDate.toISOString(),
            probe_ids:  probeIds.join(',')
        };

        return this.http.download(`${this.e}/efficiency_report`, 'GET', queryParams)
    }

    downloadProbeSoilReport(probeIds: number[]) {
        const queryParams = {
            probe_ids: probeIds.join(',')
        };

        return this.http.download(`${this.e}/soil_report`, 'GET', queryParams)
    }
}
