import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {SensorsLogComment} from "./sensors-log-comment.interface";


@Injectable({providedIn: 'root'})
export class SensorsLogCommentService {
    constructor(private http: ApiHttpService) {
    }

    list(probeId: number | string, sensorLogId: number | string, qp?: any):
        Observable<HttpResponse<SensorsLogComment[]>> {

        return this.http.get(`${this.e}/${probeId}/sensors_logs/${sensorLogId}/comments`, qp);
    }

    save(probeId: number | string, sensorLogId: number | string, data: SensorsLogComment):
        Observable<HttpResponse<SensorsLogComment>> {

        return this.http.save(`${this.e}/${probeId}/sensors_logs/${sensorLogId}/comments/{id}`, data);
    }

    remove(probeId: number | string, sensorLogId: number | string, id: number | string):
        Observable<HttpResponse<void>> {

        return this.http.remove(`${this.e}/${probeId}/sensors_logs/${sensorLogId}/comments/${id}`);
    }

    readonly e = '/probes';
}
