import {OnDestroy} from "@angular/core";
import {Subject} from "rxjs";


export class ExComponent implements OnDestroy {
    protected unsubscribe: Subject<void>;
    public ui: any = {};

    constructor() {
        this.unsubscribe = new Subject();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
