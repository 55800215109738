import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import * as _ from "lodash";
import {BsModalRef} from "ngx-bootstrap";
import {ToastrService} from "ngx-toastr";
import {UploaderOptions, UploadInput, UploadOutput, UploadStatus} from "ngx-uploader";
import {ApiSdkService} from "../../../core/api-sdk/api-sdk.service";
import {LoggerService} from "../../../core/logger.service";


@Component({
    templateUrl: './file-uploader-modal.component.html',
    styleUrls:   ['./file-uploader-modal.component.scss']
})
export class FileUploaderModalComponent implements OnInit {
    @ViewChild('fileUploader') fileUploader: ElementRef;

    modalTitle: string;
    objectId: string | number;
    target: string;
    accept: string;
    options: UploaderOptions;
    uploadInput: EventEmitter<UploadInput>;
    name: string;
    uploadOutput: EventEmitter<UploadOutput>;
    ui: any;
    response: EventEmitter<any>;

    constructor(public bsModalRef: BsModalRef,
                private l: LoggerService,
                private api: ApiSdkService,
                private t: ToastrService) {
    }

    private _files: Array<UploadOutput>;

    get files() {
        return this._files;
    }

    ngOnInit() {
        this.options = {concurrency: 1};
        this.uploadInput = new EventEmitter<UploadInput>();
        this.uploadOutput = new EventEmitter<UploadOutput>();
        this._files = [];
        this.ui = {laddaUpload: false};
    }

    onUploadOutput(e: UploadOutput) {
        this.l.debug('UploadOutput', e);

        this.uploadOutput.emit(e);

        switch (e.type) {
            case 'addedToQueue':
                this._files = [];
                this._files.push(e);
                break;
            case 'uploading':
                this.ui.laddaUpload = true;
                break;
            case 'done':
                this.ui.laddaUpload = false;
                if (e.file.responseStatus == 201) {
                    this._files = [];
                    this.uploadInput.emit({type: 'removeAll'});
                    this.fileUploader.nativeElement.value = '';
                    this.name = '';
                    this.t.success('File has been successfully.');
                    this.response.emit({type: 'success', response: e.file.response});
                } else {
                    this.t.error(e.file.response.error.detail);
                    this.files[0].file.progress.status = UploadStatus.Queue;
                    this.response.emit({type: 'error', response: e.file.response});
                }
                break;
            default:
                break;
        }
    }

    startUpload(): void {
        if (this.files.length == 0) return;

        const header = this.api.http.getHeader(),
            data = _.omitBy({
                target:    this.target,
                object_id: this.objectId as string,
                name:      this.name
            }, _.isNil);

        delete header['Content-Type'];

        const event: UploadInput = {
            type:    'uploadAll',
            url:     this.api.http.getUrl('/cloudfiles'),
            method:  'POST',
            data:    data,
            headers: header
        };

        this.uploadInput.emit(event);
    }

}
