import {HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {pluck} from "rxjs/operators";
import {ApiHttpService} from "../api-http.service";
import {Probe, SensorLog} from "./probe.interface";


@Injectable({providedIn: 'root'})
export class ProbeService {
    constructor(private http: ApiHttpService) {
    }

    list(qp?: any): Observable<HttpResponse<Probe[]>> {
        return this.http.get(this.e, qp);
    }

    save(data: Probe): Observable<HttpResponse<Probe>> {
        return this.http.save(this.e + '/{id}', data);
    }

    remove(id: number | string): Observable<HttpResponse<void>> {
        return this.http.remove(this.e + `/${id}`);
    }

    get(id: number | string, qp?: any): Observable<HttpResponse<Probe>> {
        return this.http.get(this.e + `/${id}`, qp);
    }

    sensorsLog(probeId: number | string, qp?: any): Observable<HttpResponse<SensorLog[]>> {
        return this.http.get(`${this.e}/${probeId}/sensors_logs`, qp)
    }

    listTags(term: string): Observable<string[]> {
        return this.http.get(`${this.e}/tags`, {term: term}).pipe(pluck('body'))
    }

    downloadCSV(id: number | string, qs: any = {}) {
        return this.http.download(`${this.e}/${id}/sensors_logs/actions/download`, 'GET', qs);
    }

    readonly e = '/probes';
}
