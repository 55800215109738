import {HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {ApiHttpService} from "../api-http.service";
import {Organization} from "./organization.interface";


@Injectable({providedIn: 'root'})
export class OrganizationService {
    private readonly e = '/organizations';

    constructor(private http: ApiHttpService) {
    }

    list(qp?: any): Observable<HttpResponse<Array<Organization>>> {
        return this.http.get(this.e, qp);
    }

    save(data, qp?: any): Observable<HttpResponse<Organization>> {
        return this.http.save(this.e + '/{id}', data, qp);
    }

    get(id: number | string, qp?: any): Observable<HttpResponse<Organization>> {
        return this.http.get(`${this.e}/${id}`, qp);
    }
}
